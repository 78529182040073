import store from "@/store"
import MapView from "@/components/MapView"
import Searchbtn from "@/components/Input/Searchbtn"
import SearchdataBox from "@/components/SearchdataBox"
import NewSearchBox from "@/components/NewSearchBox"
import draggable from 'vuedraggable';
import filter from '@/service/filter/filter.sub';
import sup from "@/service/support";
import SelectDrop from "@/components/Input/SelectDrop";
import EditSchedulePage from "@/components/EditSchedule";
import ComfirmBox from "@/components/ComfirmBox";
import EditPoint from "@/components/EditPoint";
import AddPoint from "@/components/AddPoint";
import EditElement from "@/components/EditElement";
import PayList from "@/components/PayList";
import LoadingBlock from "@/components/Loading/LoadingBlock";
import UpdateImage from "@/components/Image/UpdateImage";
import UpdateGoogleIframe from "@/components/UpdateGoogleIframe";
import http from "@/service/support"
import {
  Carousel,
  Slide
} from 'vue-carousel';
import {
  Handler
} from "leaflet";
import {
  each
} from "jquery"
import {
  head
} from "lodash"
import {
  BIconTelephonePlusFill
} from "bootstrap-vue"
import {
  _
} from "core-js"

let Treedata = {
  data: [{
    name: "Day1",
    droppable: true,
    children: [{
        name: "Sch1"
      },
      {
        name: "Sch2"
      },
      {
        name: "Sch3"
      }
    ]
    // isDragPlaceHolder:true
  }, {
    name: "Day2",
    droppable: true,
    children: []
    // isDragPlaceHolder:true
  }, ]
};
let sdl = {};
let vm;
let listMethods = {
  showHideBox() {
    this.$store.commit("schedule/setHideSearchBox", !this.IsSearchHide);
  },
  openSearchBox() {
    this.$store.commit("schedule/setShowSearchBox", !this.IsSearchBox);
  },
  tackQuote(data) {
    this.$router.push({
      name: "Quote",
      params: {
        "Schedule": data
      }
    })
  },
  EditReback(data) {
    store.commit("schedule/SetScheduleDay", data.result);
  },
  PayListReback(data) {},
  OpenPayList(data) {
    this.PayListbox.show = !this.PayListbox.show;
  },
  EditScheduleInfo() {
    this.EditScheduleInfoShow.show = true;
  },
  EditScheduleBtn() {
    // console.log(this.Secheduleinfo);
    store.dispatch("schedule/EditScheduleBtn", this.EditScheduleInfoShow);
    return false;
  },
  selectedquittype(text) {
    this.quitdata.method = text;
  },
  treeclick(data, store) {
    console.log(data, store);
  },
  onEnd(data) {
    console.log(data);
    return false;
  },
  SearchData(searchtype = "") {
    this.loading = true;
    this.dropdownOpen = false;

    this.quitdata.searchtype = (searchtype || this.quitdata.searchtype);
    const quitdata = this.quitdata;

    this.IsSearchBox = this.IsSearchHide = true;
    this.Searchbox = '';

    const scale = sup.zoom_scale(this.mapdata.mapcenter[0], this.mapdata.zoom, document
      .querySelector("#osmmap").clientWidth);

    const response = {
      method: quitdata.method,
      query: {
        lat: this.mapdata.mapcenter[0],
        lng: this.mapdata.mapcenter[1],
        searchmethod: quitdata.method,
        scale: scale,
        searchtext: this.quitdata.searchtext
      },
      searchtype: quitdata.searchtype,
    };

    this.SearchPlace = "";

    store.dispatch("schedule/SearchData", response).then(() => {
      this.loading = false;
    });
  },

  moreSearchData(page) {
    const temp = _.cloneDeep(this.searchRequest);
    temp.pagetoken = page;

    store.dispatch("schedule/moreSearchData", temp);
  },

  AddDay(number) {
    let lastDay = this.ScheduleList[this.ScheduleList.length - 1].start;
    let temp = this.$moment(lastDay).add("days", number).format("YYYY/MM/DD");

    this.Confirm.name = "新增天數";
    this.Confirm.context = "<span>請問要增加 : <span class='text-danger'>" + temp +
      "</span> 這天嗎？</span>";
    this.Confirm.show = true;
    this.Confirm.okfunc = () => {
      store.dispatch("schedule/AddDay", number);
    }

    // store.dispatch("schedule/AddDay", number);
  },

  AddBoxClose() {
    this.pointInfo = {
      selectday: 1,
      method: "",
      pointname: "",
      timetype: "",
      edittype: "",
      stoptime: {
        hour: "1",
        min: "20"
      },
      place_info: {},
      moveTime: {
        method: "",
        hour: 0,
        min: 0
      }
    }
  },
  DeleteDay(uid, cancel) {
    this.Confirm.name = "刪除確認";
    this.Confirm.context = "請問是否要刪除？";
    this.Confirm.show = true;
    this.Confirm.okfunc = () => {
      store.dispatch("schedule/deleteDay", {
        data: {
          uid: uid
        },
        method: "Day"
      }).then(() => {
        cancel();
      });
    }
  },
  DeleteSchedule(day, data) {
    this.Confirm.name = this.lang.Confirm.deleteTitle;
    this.Confirm.context = this.lang.Confirm.deleteDay + data.start;
    this.Confirm.show = true;
    this.Confirm.okfunc = () => {
      store.dispatch("schedule/deleteDay", {
        data: data,
        method: "Point"
      });
    }
  },
  ChangeDayAll(change = true) {
    if (!_.isEqual(this.ScheduleList, this.modifyScheduleList) || change) {
      store.dispatch("schedule/SetChangeSchedule", this.ScheduleList).then((data) => {
        this.changeTime = 0;

        this.changeDayListTemp = {
          before: [],
          after: [],
          temp: []
        }
      });
    }
  },
  EditPoint(keys, info, index) {
    // if (!this.data.Edit) {
    //   this.$store.dispatch("Public/addAlert", {
    //     title: "權限不足",
    //     type: "danger",
    //     msg: "您沒有新增、修改的權限"
    //   })
    //   return;
    // }
    this.pointInfo.pointname = info.title;
    this.pointInfo.place_info = info;
    this.pointInfo.selectPoint = info.orders + "_" + info.belong_day;
    this.pointInfo.selectday = keys + 1;
    this.pointInfo.method = info.tagMethod;
    this.pointInfo.timetype = info.maintypes;
    this.pointInfo.index = index;
    this.pointInfo.edittype = "update-point";
    this.pointInfo.stoptime.hour = Math.floor(info.stoptime / 60);
    this.pointInfo.stoptime.min = parseInt(info.stoptime) % 60;
    this.pointInfo.moveTime = {
      movemethod: info.movemethod || 'auto',
      hour: parseInt(info.movetime / 60).toString(),
      min: parseInt(info.movetime % 60).toString()
    }

    this.pointInfoShow = !this.pointInfoShow;
  },
  distanceCount(data) {
    this.$store.dispatch("map/distanceCount", data.uid);
  },
  DownChange(day, info, index) {
    console.log("change path");
  },

  dayshow(i) {
    i.subshow = !i.subshow;

    if (i.subshow) {
      if (i.sub.length > 0) {
        this.mapdata.mapcenter = [i.sub[0].lat, i.sub[0].lng];
      }
    };

    store.commit("schedule/SetChangeSchedule", this.ScheduleList);
  },
  Markerclick(place_id, day, index, data) {
    this.mapdata.mapcenter = [parseFloat(data.lat) + 0.005, parseFloat(data.lng)];
    const temp = place_id + day + index;
    this.ClickMyPoint = (temp == this.ClickMyPoint) ? "" : place_id + day + index;

    console.log(this.ClickMyPoint);
  },

  ondragstart(node, draggableHelperInfo) {
    return true;
  },
  ondragend(node, draggableHelperInfo) {
    return !node.parent.isRoot;
  },
  CheckMove(evt, originalEvent) {
    // ClickMyPoint==(evt.draggedContext.element.place_id+keys+index)
    // return false;

    return !!this.data.Edit;
  },
  SearchMarkClick(data) {
    this.showPointData = data;
    // this.showPointData.model="street";

    const itude = [parseFloat(data.lat) + 0.005, parseFloat(data.lng)];
    // this.$store.dispatch("map/changeZoom", 15);
    this.$store.dispatch("map/changeMapCenter", itude);

    if (data.place_id == this.SearchPlace) {
      this.SearchPlace = "";
    } else {
      // const itude = [data.lat, data.lng];
      // this.$store.commit("map/setMapCenter", itude);
      // this.$store.commit("map/setZoom", 15);
      // this.mapdata.mapcenter = [data.lat, data.lng];
      // this.mapdata.zoom = 13;
      this.SearchPlace = data.place_id;
    }
  },

  openAddBox(info) {
    if (!this.data.Edit) {
      this.$store.dispatch("Public/addAlert", {
        title: "權限不足",
        type: "danger",
        msg: "您沒有新增、修改的權限"
      })
      return;
    }

    const open = this.ScheduleList.filter((item) => item.subshow);
    const selected = Math.floor(open.length > 0 ? open[open.length - 1].orders - 1 : 0);
    this.pointInfo = {
      selectday: selected + 1,
      selectPoint: "",
      method: "",
      pointname: "",
      timetype: [],
      edittype: "", //insert-g , insert-s , update-g , update-s 
      stoptime: {
        hour: "1",
        min: "20"
      }
    };
    this.pointInfo.pointname = info.name || info.title;
    this.pointInfo.place_info = info;
    this.pointInfo.edittype = (info.method == "google") ? "insert-g" : "insert-s";
    this.pointInfoShow = true;
  },

  FilterIcon(name) {
    if (this.SelectKid) {
      return filter.filter(this.SelectKid, {
        name: name
      })[0];
    }

    return {};
  },
  CopySchedule(uid) {
    this.Confirm.show = true;
    this.Confirm.context = '確定要Copy此行程？';
    this.Confirm.name = '確定要Copy此行程？';
    this.Confirm.okfunc = () => {
      store.dispatch("schedule/CopySchedule", uid).then((data) => {
        if (data.data.state) {
          this.$router.push({
            'name': "Edit",
            params: {
              Schedule: data.data.result.Schedule.uid
            }
          });
        } else {

          this.$router.push({
            'name': "index"
          });
        }
      });
    }
  },
  StartTimeEdit(data) {
    if (!this.data.Edit) {
      this.$store.dispatch("Public/addAlert", {
        title: "權限不足",
        type: "danger",
        msg: "您沒有新增、修改的權限"
      })
      return;
    }

    let time = data.starttime.split(":");

    this.DayStartTime = {
      Startime: {
        hour: parseInt(time[0]).toString(),
        min: parseInt(time[1]).toString()
      },
      show: true,
      uid: data.uid
    }
  },
  hideStartTime() {
    this.DayStartTime = {
      Startime: {
        hour: "",
        min: ""
      },
      show: "",
      uid: ""
    }
  },

  EditStartTimeOk() {
    store.dispatch("schedule/EditStart", this.DayStartTime);
  },

  getschedule() {

    store.dispatch("schedule/getScheduleDay", this.$route.params.Schedule).then((data) => {
      let tlet = this.mapdata.mapcenter[0];
      let tlng = this.mapdata.mapcenter[1];

      if (this.ScheduleList[0] && this.ScheduleList[0].sub.length > 0) {
        tlet = this.ScheduleList[0].sub[0].lat;
        tlng = this.ScheduleList[0].sub[0].lng;
      } else {
        if (store.state.schedule.edit.FirstLocation.length > 0) {
          tlet = new Number(store.state.schedule.edit.FirstLocation.lat)
            .toFixed(7);
          tlng = new Number(store.state.schedule.edit.FirstLocation.lng)
            .toFixed(7);
        }
      }
      this.mapdata.mapcenter = [
        tlet,
        tlng
      ];

      this.baseSchedule = _.cloneDeep(this.ScheduleList);
      this.changeDayListTemp.temp = _.cloneDeep(this.ScheduleList);
    });
  },
  automyLocation() {
    store.dispatch("schedule/setLocationLog", this.autoSetLocation);
    this.tmpdate = this.$moment().format("s");

    if (this.autoSetLocation) {
      setTimeout(this.automyLocation, 10000);
    }
  },

  autoset(uid) {
    this.autoSetLocation = !this.autoSetLocation;
    if (this.autoSetLocation) {
      store.dispatch("schedule/getShoeLog").then((data) => {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log(position);
          this.mapdata.zoom = 16;
          this.mapdata.mapcenter = [position.coords.latitude, position.coords.longitude];
        }, (error) => {

        });
        const date = this.$moment().format("YY-MM-DD");
        store.commit("schedule/setOpenShoeDate", date);
        this.automyLocation();
      });
    }

  },

  tagChange(tag, page = null) {
    if (tag == 'smopen') {
      this.smopen = !this.smopen;
      this.$store.commit("Public/setOpenSearch", false);

      if (page) {
        tag = page;
        this.tagSelect = tag;
      }

    } else {
      this.tagSelect = tag;
    }

    if (tag == "searchBox") {
      this.$store.commit("Public/setOpenSearch", true);
    } else {
      this.$store.commit("Public/setOpenSearch", false);
    }

  },

  changeDayList() {
    const temp = _.mapValues(this.ScheduleList, (item, key) => {
      return {
        maxprice: item.maxprice,
        nowprice: item.nowprice,
        orders: item.orders,
        start: item.start,
        starttime: item.starttime,
        sub: item.sub,
        uid: item.uid
      }
    });

    const modifyTemp = _.mapValues(this.modifyScheduleList, (item, key) => {
      return {
        maxprice: item.maxprice,
        nowprice: item.nowprice,
        orders: item.orders,
        start: item.start,
        starttime: item.starttime,
        sub: item.sub,
        uid: item.uid
      }
    });

    if (!_.isEqual(temp, modifyTemp)) {
      this.changeTime += 1;
      store.commit("schedule/SetChangeSchedule", this.ScheduleList);

      const temp = JSON.stringify(this.changeDayListTemp.temp);

      if (this.changeDayListTemp.before.length < 5) {
        this.changeDayListTemp.before.push(temp);
      } else {
        this.changeDayListTemp.before.splice(this.changeDayListTemp.before.length, 0, temp);
        this.changeDayListTemp.before.splice(0, 1);
      }

      this.ScheduleList = this.loadSchedule()

      this.changeDayListTemp.temp = _.cloneDeep(this.ScheduleList);
    }

  },

  resetDayList() {
    if (this.changeDayListTemp.before.length < 1) {
      return
    }

    const temp = this.changeDayListTemp.before.pop();
    const beforeTemp = JSON.parse(temp);

    const listTemp = JSON.stringify(this.changeDayListTemp.temp);

    if (this.changeDayListTemp.after.length < 5) {
      this.changeDayListTemp.after.push(listTemp);
    } else {
      this.changeDayListTemp.after.splice(this.changeDayListTemp.after.length, 0, listTemp);
      this.changeDayListTemp.after.splice(0, 1);
    }

    this.changeDayListTemp.temp = _.cloneDeep(beforeTemp);

    store.commit("schedule/SetChangeSchedule", beforeTemp);
  },

  cancelResetDayList() {
    if (this.changeDayListTemp.after.length < 1) {
      return
    }

    const temp = this.changeDayListTemp.after.pop();
    const afterTemp = JSON.parse(temp);
    const listTemp = JSON.stringify(this.changeDayListTemp.temp);


    if (this.changeDayListTemp.before.length < 5) {
      this.changeDayListTemp.before.push(listTemp);
    } else {
      this.changeDayListTemp.before.splice(this.changeDayListTemp.before.length, 0, listTemp);
      this.changeDayListTemp.before.splice(0, 1);
    }
    this.changeDayListTemp.temp = _.cloneDeep(afterTemp);
    store.commit("schedule/SetChangeSchedule", afterTemp);
  },

  saveSchedule() {
    this.ChangeDayAll();
  },

  loadSchedule() {
    let firstTemp = _.head(this.baseSchedule);

    let temp = _.map(this.ScheduleList, (items, keys) => {

      let copyItem = _.cloneDeep(items);
      copyItem.start = this.$moment(firstTemp.start).add("days", keys).format("YYYY/MM/DD");
      let temp = _.cloneDeep(copyItem.sub);
      let nextTime = null;

      copyItem.sub = _.map(temp, (item, key) => {
        if (!(temp.length == key + 1)) {
          let nextItem = temp[key + 1];

          let latlng = [{
            lat: item.lat,
            lng: item.lng
          }, {
            lat: nextItem.lat,
            lng: nextItem.lng
          }];
          item.distance = this.getDistance(latlng);

          item.movetime = Math.round(item.distance / ((item.distance < 30) ? 20 : 60) *
            60);
        }

        item.starttime = (key == 0) ? items.starttime : nextTime;

        let subStartTime = parseInt(item.starttime.split(":")[0]) * 60 + parseInt(item
          .starttime.split(":")[1]);

        let subEndTime = subStartTime + parseInt(item.stoptime);

        item.endtime = this.numberFormat(Math.floor(subEndTime / 60), 10) + ":" + this
          .numberFormat((subEndTime % 60), 10);

        let nextTemp = parseInt(item.movetime) + subEndTime;
        nextTime = this.numberFormat(
            Math.floor(nextTemp / 60), 10) + ":" + this
          .numberFormat((nextTemp % 60), 10);

        return item;
      });

      return copyItem;
    });

    return temp;

  },

  getDistance(data) {
    let EARTH_RADIUS = 6378.137;

    let latlng1 = data[0];
    let latlng2 = data[1];

    let radLat1 = this.rad(latlng1["lat"]);
    let radLat2 = this.rad(latlng2["lat"]);

    let a = radLat1 - radLat2;
    let b = this.rad(latlng1["lng"]) - this.rad(latlng2["lng"]);
    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(
      radLat2) * Math.pow(Math.sin(b / 2), 2)));

    s = s * EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000 * 1.6;

    return s.toFixed(2);
  },

  rad(d) {
    return d * Math.PI / 180.0;
  },

  numberFormat(number, max) {
    if (number < max) {
      return "0" + number;
    }
    return number.toString();
  },

  closeShowPoint() {

    if (this.showPointEdit) {
      this.Confirm.show = true;
      this.Confirm.name = "編輯放棄";
      this.Confirm.context = "系統可能不會儲存您所做的變更";
      this.Confirm.okfunc = () => {
        this.showPointEdit = false;
        this.showPoint = false;
      }
    } else {
      this.showPoint = false;
    }

  },

  showPointInfor(data, source = "search") {
    console.log(data);
    if (this.showPointEdit) {
      this.Confirm.show = true;
      this.Confirm.name = "編輯放棄";
      this.Confirm.context = "系統可能不會儲存您所做的變更";
      this.Confirm.okfunc = () => {
        this.showPointEdit = false;
        this.tackPointInfor(data, source)
      }
      return;
    }

    this.tackPointInfor(data, source)
  },

  tackPointInfor(data, source) {
    let field = "place_id";
    this.scrollPoint.temp = this.SearchList.data;

    if (source == "schedule") {
      this.scrollPoint.temp = this.schedulePoint;
      field = "uid";
    }

    this.scrollPoint.index = _.findIndex(this.scrollPoint.temp, (item) => {
      return (item[field] == data[field]);
    });

    this.$store.dispatch("schedule/getOnePoint", data.place_id);
    this.showPoint = true;
  },
  changeShowPoint(method) {
    console.log(this.scrollPoint, method);
    if (method == "next") {
      if (this.scrollPoint.index == this.scrollPoint.temp.length - 1) {
        console.log("last");
      } else {
        this.scrollPoint.index += 1;
        let temp = this.scrollPoint.temp[this.scrollPoint.index];
        this.$store.dispatch("schedule/getOnePoint", temp.place_id);
      }
    } else if (method == "pre") {
      if (this.scrollPoint.index == 0) {
        console.log("first");
      } else {
        this.scrollPoint.index -= 1;
        let temp = this.scrollPoint.temp[this.scrollPoint.index];
        this.$store.dispatch("schedule/getOnePoint", temp.place_id);
      }
    }
  },

  editShowPoint() {
    this.showPointEdit = true;
    this.copyShowPoint();
  },

  saveShowPoint() {
    this.$store.dispatch("schedule/savePersonalPoint", this.showPointEditData).then((data) => {
      if (data.data.state) {
        this.showPointEdit = false;
      }
    });
  },

  cancelShowPoint() {
    this.showPointEdit = false;
    this.showPointData.more = false;
    this.showPointEditData = {};
  },

  copyShowPoint() {
    console.log(this.showPointData);

    const field = {
      place_id: "place_id",
      name: "name",
      descriptionDetail: "descriptionDetail",
      websiteUrl: "websiteUrl",
      phone: "phone",
      openTime: "openTime",
      address: "address",
    };

    const temp = _.cloneDeep(this.showPointData);
    this.showPointEditData = _.mapValues(field, (item, key) => {
      if (key == "descriptionDetail") {
        return temp[item] ? temp[item] : (temp["description"] ? temp["description"] : "");
      } else {
        return temp[item];
      }
    });
  },

  updateImage() {}
};

let nameComputed = {
  tranDate() {
    return (data, format) => {
      if (data && data != "0000-00-00") {
        return this.$moment(data).format(format)
      } else {
        return "";
      }
    }
  },
  formatTime() {
    return (time) => {
      const temp = [];
      time = parseInt(time);
      const hour = parseInt(time / 60);
      const minute = time % 60;

      if (hour > 0) {
        temp.push(hour + "時");
      }

      if (minute > 0) {
        temp.push(minute + "分")
      }

      return temp.join(" ");
    }
  },

  getLevel: {
    get() {
      return this.$store.getters["user/getLevel"];
    }
  },
  autoreportGPS: {
    get() {
      return store.state.schedule.autoreportGPS;
    }
  },
  Secheduleinfo: {
    get() {
      return store.state.schedule.edit.data;
    }
  },
  SearchListComputed() {
    const redata = [];
    if (this.IsSearchBox) {
      let color = (this.SearchList.method == "google") ? "#4285F4" : "#34A853";
      let tmpdata = this.SearchList || {
        data: []
      };

      if (typeof (tmpdata.data) != "string") {
        tmpdata.data.forEach((items) => {
          const tmpdata = items;
          tmpdata.color = color;
          tmpdata.method = this.SearchList.method;
          redata.push(tmpdata);
        });
      }
    }
    return redata;
  },
  ScheduleList: {
    get() {
      console.log(store.state.schedule.edit.ScheduleList);
      return store.state.schedule.edit.ScheduleList;
    },
    set(data) {
      store.commit("schedule/SetChangeSchedule", data);
    }
  },

  modifyScheduleList: {
    get() {
      return store.state.schedule.modify.ScheduleList;
    }
  },

  searchRequest: {
    get() {
      return this.$store.state.schedule.searchRequest;
    },

    set() {},
  },

  data: {
    get() {
      return store.state.schedule.edit.data
    },
    set() {

    }
  },
  SearchList: {
    get() {
      console.log(store.state.schedule.SearchData);
      return store.state.schedule.SearchData
    },
    set() {

    }
  },
  SelectKid: {
    get() {
      return store.state.schedule.edit.selectKid
    }
  },
  timeType: {
    get() {
      return store.state.schedule.edit.timetype
    }
  },
  quittype: {
    get() {
      return store.state.schedule.quittype;
    }
  },
  Loginstate: {
    get() {
      return store.state.user.Login.state
    }
  },

  subShow() {
    return (item) => {
      return (item.subshow) ? item.sub : [];
    };
  },

  mapdata: {
    get() {
      return this.$store.state.map.mapData;
    }
  },

  changeLoading: {
    get() {
      return this.$store.state.schedule.changeLoading;
    }
  },

  loading: {
    get() {
      return this.$store.state.schedule.pointLoading;
    }
  },

  IsSearchHide: {
    get() {
      return this.$store.state.schedule.hideSearchBox;
    }
  },

  IsSearchBox: {
    get() {
      return this.$store.state.schedule.showSearchBox;
    }
  },

  getRealyUrl() {
    return (path) => {
      console.log(path)
      if (path && path.indexOf("data/") > -1) {
        return this.path + path;
      } else {
        return path;
      }
    }
  },
  totalDistance() {
    return (sub) => {
      const distance = _.reduce(sub, function (distance, item, key) {
        if (item.distance) {
          distance += parseFloat(item.distance);
        }
        return distance;
      }, 0);

      return distance.toFixed(2);
    }
  },

  // showPoint: {
  //   get() {
  //     if (this.showPointData && this.showPointData.name) {
  //       return true
  //     }
  //     return false;
  //   },

  //   set(data) {
  //     if (!data) {
  //       this.showPointData = {}
  //     }
  //   }
  // },

  showPointData: {
    get() {
      return this.$store.state.schedule.showPoint;
    },
    set(data) {
      if (data) {
        data.model = "street";
        this.$store.commit("schedule/setShowPoint", data);
      }
    }
  },

  smopen: {
    get() {
      return this.$store.state.Public.smopen;
    },
    set(show) {
      this.$store.commit("Public/setSmopen", show);
    }
  },

  schedulePoint: {
    get() {
      console.log(store.getters["schedule/GetSchedule"]);
      return store.getters["schedule/GetSchedule"];
    },
  },
}
export default {
  data() {
    let lat = 25.0329693999999989273419487290084362030029296875;
    let lng = 121.5654176999999975805621943436563014984130859375;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        lat = position.coords.latitude;
        lng = position.coords.longitude;
      });
    }

    this.getschedule();

    return {
      path: process.env.VUE_APP_DATA_PATH,
      openImage: false,
      openGoogleIframe: false,
      dropdownOpen: true,
      tmpdate: "",
      autoSetLocation: false,
      dayColor: store.state.schedule.dayColor,
      PayListbox: {
        show: false
      },
      DayStartTime: {
        Startime: {
          hour: "08",
          min: "00"
        },
        show: false,
        uid: ""
      },
      draggableoption: {
        animation: 150,
        delay: 400,
      },
      EditScheduleInfoShow: {
        show: false,
        title: "",
        startdate: "",
        TotalDay: 0,
        ShareMethod: "public",
      },
      // IsSearchBox: false,
      // IsSearchHide: false,
      lang: store.state.Public.lang.schedule,
      langPub: store.state.Public.lang,
      boxMinus: true,
      image: "https://picsum.photos/id/94/600/400",
      textcolor: "text-white",
      // smopen: true,
      tagSelect: "schedule",
      Confirm: {
        show: false,
        info: {
          name: "",
          context: "",
          okfunc: Function
        }
      },
      Editdata: {
        show: false,
        info: {
          name: "",
          starttime: "",
          endttime: "",
        }
      },
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb'
      },
      // mapdata: {
      //   zoom: 13,
      //   mapcenter: [lat, lng]
      // },

      quitdata: {
        method: "point",
        searchtext: "",
        searchtype: "Preferences"
      },

      pointInfoShow: false,
      SearchPlace: "",
      ClickMyPoint: "",
      pointInfo: {
        selectday: 1,
        method: "",
        pointname: "",
        timetype: "",
        edittype: "", //insert-g , insert-s , update-g , update-s 
        stoptime: {
          hour: "1",
          min: "20"
        },
        place_info: {},
        moveTime: {
          movemethod: "",
          "hour": "0",
          "min": "0"
        }
      },

      changeDayListTemp: {
        after: [],
        before: [],
        temp: ""
      },
      changeTime: 0,
      baseSchedule: [],
      showPointEdit: false,
      showPoint: false,
      modifySchedule: false,
      scrollPoint: {
        temp: [],
        index: null
      }
    };
  },
  methods: listMethods,
  computed: nameComputed,
  created() {
    this.$store.dispatch("Public/autoSave", {
      maintable: "m_menu",
      maindata: {name:"TTA",uid:"MM22091300010B3"}
    })
  },
  watch: {
    Loginstate(data) {
      console.log(data);
    },

    // "smopen"(data) {
    //   if (!data) {
    //     this.$store.commit("Public/setSearchBox", true);
    //   } else {
    //     if (this.tagSelect == 'searchBox') {
    //       this.$store.commit("Public/setSearchBox", true);
    //     } else {
    //       this.$store.commit("Public/setSearchBox", false);
    //     }
    //   }
    // },

    // "tagSelect"(data) {
    //   if (data == 'searchBox') {
    //     this.$store.commit("Public/setSearchBox", true);
    //   } else {
    //     this.$store.commit("Public/setSearchBox", false);
    //   }
    // }

    // mapdata: {
    //   handler: (data) => {
    //     console.log(data)
    //   },
    //   deep: true
    // }
  },

  components: {
    MapView,
    Searchbtn,
    AddPoint,
    SearchdataBox,
    draggable,
    SelectDrop,
    EditSchedulePage,
    ComfirmBox,
    EditPoint,
    EditElement,
    PayList,
    LoadingBlock,
    NewSearchBox,
    UpdateImage,
    UpdateGoogleIframe,
    Carousel,
    Slide
  },
};
