<template>
  <div class="bga-2 position-absolute h-100 w-100 d-flex align-items-center justify-content-center" v-if="showLoading">
    <span class="font-up-10">Loading ... </span>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: {
      showLoading: Boolean,
    },
  }

</script>
