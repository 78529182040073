<template>
  <div class="card bga-0 m-0">

    <div class="card-header pms-bg-blue font-up-0 text-white px-2 py-1">
      <div class="d-flex align-items-center justify-content-between">
        <div class="flex-fill d-flex align-items-center text-dark">
          <span>{{lang.SearchBox[SearchData.method]}}</span>

          <span class="pl-2">筆數 : {{SearchData.data.length}} / {{SearchData.count}} </span>
        </div>

        <div class="d-flex text-dark">
          <div class="d-flex align-items-center justify-content-center mr-2">
            <span class="font-up-0 badge badge-pill badge-secondary mr-2"
              @click="sortChange()">{{sortMethodName()}}</span>
            <span class="font-up-0 badge badge-pill badge-secondary"
              @click="sortByChange()">{{sortByMethodName()}}</span>
          </div>
          <div class="px-2  cur-print" @click='showSearchBox()'>
            <font-awesome-icon :icon="['fas', 'angle-up']" size="lg" v-if='!IsSearchHide' />
            <font-awesome-icon :icon="['fas', 'angle-down']" size="lg" v-if='IsSearchHide' />
          </div>
          <div class="px-2 cur-print" @click='hideSearchBox()'>
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </div>
        </div>
      </div>
    </div>

    <div class="pms-card-list-box position-relative" v-show='IsSearchHide'>


      <!-- old -->
      <!-- <div class="d-inline-flex overflow-auto" :style='SearchBoxListStyle'> -->
      <!-- new -->
      <div class="position-absolute d-flex align-items-center justify-content-center rounded-circle bg-dagee shadow-sm"
        style="right: 5px; bottom: 5px; z-index: 2; min-width: 50px; min-height: 50px" @click="goFirst()">
        <b class="text-dark">第一筆</b>
      </div>

      <div class="list-box" style="overflow-y: hidden; overflow-x: auto ">
        <div class="d-inline-flex pb-2 pt-2 pb-md-4 ">
          <!-- <LoadingBlock :showLoading="showLoading"></LoadingBlock> -->

          <div class="card d-flex align-items-stretch pms-card-list m-0 mx-2"
            :class='[{"pms-search-box-active": SearchSelected==i.place_id }, "box"+i.place_id]'
            v-for='(i, index) in orderByPoint' :key="index" @click="SearchMarkClick(i)">
            <!-- SearchData.data -->
            <!-- <div class="row no-gutters"> -->
            <!-- <div class="col-12 align-self-center" v-if='i.photo'>
            <img
              :src="`https://maps.googleapis.com/maps/api/place/photo?photoreference=`+i.photo[0].photo_reference+`&sensor=false&maxheight=600&maxwidth=600&key=`+GoogleAPI"
              class="card-img" />
          </div> -->
            <!-- <div class="col-md-12"> -->
            <!-- :class="{'mt-n5':i.photo}" -->
            <div class="card-body d-flex flex-wrap p-0" style="height:10rem">
              <div
                class="w-100 card-title pms-title-opacity d-flex align-items-center justify-content-between m-0">
                <font-awesome-icon class="text-warning" :icon="['fas', 'star']" size="lg" />

                <b class="text-center flex-fill pms-text-nowrap text-dark">{{i.name}}</b>

                <font-awesome-icon class="cur-print text-dark" :icon="['fas', 'plus']" size="lg"
                  @click='AddSchedule(i)' />
              </div>

              <div class="w-100 card-text p-2 flex-fill h-100">
                <div class="d-flex flex-column  h-100">
                  <div class="w-100 d-flex">
                    <span class="pr-2 text-nowrap">{{lang.text.address}}</span>
                    <span class="flex-fill pms-text-nowrap">
                      {{i.address}}
                    </span>
                  </div>
                  <div class="w-100 d-flex flex-fill h-100" style="max-height: 3rem;">
                    <span class="pr-2 text-nowrap">{{lang.text.info}}</span>
                    <span :id="'place__infor__' + index" class="flex-fill h-100 pms-content-2">
                      {{i.descriptionDetail ? i.descriptionDetail : i.description}}
                    </span>

                    <!-- <b-tooltip :target="'place__infor__'+index">
                    <small>{{i.info ? i.info : i.infoDetail}}</small>
                  </b-tooltip> -->
                  </div>
                  <div class="w-100 d-flex">
                    <span class="pr-2 text-nowrap">距離</span>
                    <span class="flex-fill text-right" v-if="i.distance">
                      {{i.distance | fixed(2)}} M
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-right px-2 py-1">
              <span class="text-success" v-if="i.price">
                {{i.price.price|currency()}}
                <small>起</small>
              </span>
              <span class="text-muted" v-else>-</span>
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-center text-white font-up-4"
            style="min-width: 5rem;" @click="moreClick()">
            <b>more</b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="card-body">
      <h5 class="card-title">Special title treatment</h5>
      <p class="card-text">With supporting text below as a natural lead-in to additional content.
      </p>
      <a href="#" class="btn btn-primary">Go somewhere</a>
    </div> 
  </div>
  -->
</template>

<script>
  import ctrl from "./js/SearchdataBox";
  export default ctrl;

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
