<template>
  <div class="w-100">
    <div style="height:70px" v-if="!searchBarShow"></div>
    <div class="w-100 overflow-auto" :style="{'height': !searchBarShow ? 'calc(100% - 70px)': '100%'}">
      <div class="card-group d-flex flex-column p-2">
        <div class="card border" v-for="(data,index) in SearchData.data">
          <div class="card-title d-flex align-items-center bg-dagee-blue cur-print p-2 m-0">
            <font-awesome-icon class="text-warning" :icon="['fas', 'star']" size="lg" />

            <b class="text-center flex-fill pms-text-nowrap text-dark"
              @click="showPointInfor(data)">
              {{data.name}}
            </b>

            <font-awesome-icon class="text-dark" :icon="['fas', 'plus']" size="lg"
              @click='AddSchedule(data)' />
          </div>

          <div class="card-body p-2" @click="SearchMarkClick(data)">
            <div class="d-flex align-items-center justify-content-center mb-2 overflow-hidden"
              style="height: 150px">
              <img src="../assets/img/error1.jpg" class="w-100 card-img-top" alt=""
                v-if="data.image.length == 0">

              <img :src="getRealyUrl(data.image[0].image)" class="w-100 card-img-top"
                :alt="data.image[0].name" v-if="data.image.length > 0">
            </div>
            <div class="d-flex">
              <span class="pr-2 text-nowrap">{{lang.text.address}}</span>
              <span class="flex-fill">
                {{data.address}}
              </span>
            </div>
            <div class="d-flex flex-fill h-100" style="max-height: 4rem;">
              <span class="pr-2 text-nowrap">{{lang.text.info}}</span>
              <span :id="'place__infor__' + index" class="flex-fill h-100 pms-content-2">
                {{data.descriptionDetail ? data.descriptionDetail : data.description}}
              </span>
            </div>
            <div class="d-flex flex-fill h-100" style="max-height: 4rem;">
              <span class="pr-2 text-nowrap">距離</span>
              <span :id="'place__infor__' + index" class="flex-fill h-100 text-right"
                v-if="data.distance">
                {{data.distance | fixed(2)}} M
              </span>
            </div>
          </div>

          <div class="card-footer text-right p-2">
            <span class="text-success" v-if="data.price">
              {{data.price.price|currency()}} <small>起</small>
            </span>
            <span class="text-muted" v-else>-</span>
          </div>
        </div>

        <div class="w-100 d-flex align-items-center justify-content-center" style="height: 3rem" @click="moreClick()">
          <b class="font-up-4">more</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ctrl from "./js/NewSearchBax.js";
  export default ctrl;

</script>
