import store from "@/store"
import CheckBtn from '@/components/Input/CheckBtn';
export default {
  name: 'Editschedule',
  methods: {
    hidebox() {
      this.$emit("update:show", false);
    },
    SelectMethod(value, method) {
      let tmpmethod = [];
      let tmpparallel = [];
      value.sub.forEach((item, keys) => {
        const index = item.maintypes.indexOf(method.value);
        if (index > -1) {
          tmpmethod.push(item);
        }
        item.parallel.forEach((item, keys) => {
          const index = item.maintypes.indexOf(method.value);
          if (index > -1) {
            tmpparallel.push(item);
          }
        });
      });
      //  console.log(method.value,tmpmethod, tmpparallel);
      return {
        MainMethod: tmpmethod,
        ParallelMethod: tmpparallel
      };
    },
  },
  computed: {
    timetype: {
      get() {
        return store.state.schedule.edit.timetype;
      }
    },
    ScheduleList: {
      get() {
        return store.state.schedule.edit.ScheduleList;
      }
    },
    lang: {
      get() {
        return store.state.Public.lang.schedule;
      }
    },
    langPub: {
      get() {
        return store.state.Public.lang;
      }
    }
  },
  components: {
    CheckBtn
  },
  filters: {

  },
  data() {
    // this.getSchedule(this.Secheduleuid);
    return {
      boxshow: false,

      showitem: []
    }
  },
  watch: {
    "show"(data) {
      this.boxshow = data;
      if (data) {

      } else {
        this.showitem = [];
      }
    }
  },
  props: {
    Secheduleuid: String,
    show: {
      default: true,
      type: Boolean,

    },
    reback: Function
  },
};
