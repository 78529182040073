export default {
  data() {
    return {
      path: process.env.VUE_APP_DATA_PATH
    }
  },

  computed: {
    lang: {
      get() {
        return this.$store.state.Public.lang.schedule
      }
    },

    searchBarShow: {
      get() {
        return this.$store.state.Public.searchBar;
      }
    },

    getRealyUrl() {
      return (path) => {
        if (path.indexOf("data/") > -1) {
          return this.path + path;
        } else {
          return path;
        }
      }
    },
  },

  methods: {
    moreClick() {
      const count = this.SearchData.count;
      const page = this.SearchData.nextpage_token;
      this.$emit("moreSearchData", page);
    }
  },

  props: {
    IsOpen: Boolean,
    SearchData: Object,
    SearchMarkClick: Function,
    SearchSelected: String,
    IsSearchHide: Boolean,
    AddSchedule: Function,
    loading: Boolean,
    showPointInfor: Function,
  },
}
