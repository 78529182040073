<template>
  <div class="d-flex p-0 position-relative">
    <!-- phone -->
    <div class='pms-absolut-left-btn-xx' v-if='!smopen'>
      <ul>
        <li @click="tagChange('smopen', 'schedule')">
          <a>{{lang.title}}</a>
        </li>
        <li>
          <a @click="tagChange('smopen', 'searchBox')">搜尋</a>
        </li>
      </ul>
    </div>
    <!-- phone -->
    <div class="pms-point-introduction d-flex flex-column flex-fill"
      v-if="showPoint && showPointData">
      <div class="d-flex align-items-center justify-content-center p-1 bg-dagee introduction-title">
        <button class="btn btn-sm text-danger">
          <font-awesome-icon :icon="['far', 'heart']" size='lg' />
        </button>
        <div class="flex-fill">
          <b class="w-100 pms-content-1">{{showPointData.name}}</b>
        </div>
        <button class="btn btn-sm" @click="closeShowPoint()">
          <font-awesome-icon :icon="['fas', 'times']" size='lg' />
        </button>
      </div>

      <div class="flex-fill" style="height: inherit">
        <div class="w-100 h-100 overflow-auto">

          <b-card :body-class="['p-0']">

            <carousel :perPage="1" :paginationPadding="10" :paginationSize="5"
              v-if="showPointData.streetAndImage.length > 0">
              <slide v-for="slide in showPointData.streetAndImage">
                <div class="w-100" style="min-height: 250px">
                  <div class="w-100" style="height: 250px" v-html="slide.html"
                    v-if="slide.type && slide.type == 'street'">
                  </div>

                  <div class="w-100 d-flex flex-column" style="min-height: 250px" v-else>
                    <b-img class="w-100 flex-fill" :src="getRealyUrl(slide.image)" rounded
                      :alt="slide.name">
                    </b-img>

                    <div class="d-flex align-items-center justify-content-between px-2">
                      <div class="flex-fill d-flex align-items-center justify-content-start">
                        <span class="pr-2">By</span>
                        <small class="pms-content-1"
                          style="width:100px; max-width: 100px">{{slide.belong_user}}</small>
                      </div>

                      <div class="flex-fill d-flex align-items-center justify-content-end">
                        <span class="pr-2">Source</span>
                        <small class="pms-content-1"
                          style="width:100px; max-width: 100px">{{slide.source}}</small>
                      </div>
                    </div>
                    <!-- <div class="d-flex align-items-center justify-content-end">

                    </div> -->
                  </div>
                </div>


                <!-- <b-img class="w-100 flex-fill" :src="getRealyUrl(image.image)" rounded
                  :alt="image.name">
                </b-img>
                <div class="d-flex align-items-center justify-content-end">
                  <small>上傳者</small>
                  <small class="text-right" style="min-width:100px">{{image.belong_user}}</small>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <small>來源</small>
                  <small class="text-right" style="min-width:100px">{{image.source}}</small>
                </div> -->
              </slide>

            </carousel>
            <img src="../../assets/img/error1.jpg" class="w-100 card-img-top" alt="" v-else>
            <!-- <b-nav class="pt-2 px-2" tabs fill>
              <b-nav-item @click="showPointData.model = 'street'" :active="showPointData.model == 'street'">街景</b-nav-item>
              <b-nav-item @click="showPointData.model = 'image'" :active="showPointData.model == 'image'">圖片</b-nav-item>
            </b-nav> -->

            <!-- <div style="height: 250px" v-html="showPointData.iframe" v-if="showPointData.model == 'street'">
            </div>

            <div v-else style="min-height: 250px;"
              class="w-100 d-flex align-items-center justify-content-center position-relative p-2">

              <div class="w-100 flex-fill d-flex flex-column" v-if="showPointData.image.length > 0">
                <carousel :perPage="1" :paginationEnabled="false">
                  <slide v-for="image in showPointData.image">
                    <b-img class="w-100 flex-fill" :src="getRealyUrl(image.image)" rounded
                      :alt="image.name">
                    </b-img>
                    <div class="d-flex align-items-center justify-content-end">
                      <small>上傳者</small>
                      <small class="text-right"
                        style="min-width:100px">{{image.belong_user}}</small>
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                      <small>來源</small>
                      <small class="text-right" style="min-width:100px">{{image.source}}</small>
                    </div>
                  </slide>

                </carousel>
                <b-img class="w-100 flex-fill" :src="getRealyUrl(showPointData.image[0].image)" rounded
                  :alt="showPointData.image[0].name">
                </b-img>
              </div>

              <img src="../../assets/img/error1.jpg" class="w-100 card-img-top" alt="" v-else>
            </div> -->

            <div class="w-100 d-inline-flex align-items-center justify-content-between px-3 my-2"
              v-if="!showPointEdit">
              <button class="btn btn-sm btn-outline-primary" @click="changeShowPoint('pre')">
                <font-awesome-icon :icon="['fas', 'chevron-left']" size='lg' />
              </button>

              <div class="flex-fill d-flex align-items-center justify-content-around">
                <a :href='"https://www.google.com/search?q="+showPointData.name'
                  target='_blank'>搜尋</a>

                <a :href='"https://www.google.com/maps?q="+showPointData.name'
                  target='_blank'>Map</a>

                <!-- <a href="#" class="cur-print" @click='AddSchedule(i.info)'
                  v-if="!!GetScheduleInfo.Edit">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </a> -->

                <button class="btn btn-sm text-primary" @click="editShowPoint()" v-if="getLevel">
                  <font-awesome-icon :icon="['fas', 'edit']" size='lg' />
                </button>
              </div>

              <button class="btn btn-sm btn-outline-primary" @click="changeShowPoint('next')">
                <font-awesome-icon :icon="['fas', 'chevron-right']" size='lg' />
              </button>
            </div>

            <b-list-group class="text-secondary border-top" flush v-if="!showPointEdit">
              <b-list-group-item>
                <div class="d-flex align-items-start">
                  <div style="width: 30px;">
                    <font-awesome-icon :icon="['fas', 'pencil-alt']" size='lg' />
                  </div>
                  <div class="flex-fill d-flex flex-column pl-2">
                    <b>介紹</b>
                    <span>{{showPointData.descriptionDetail ? showPointData.descriptionDetail : showPointData.description}}</span>
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item>
                <div class="d-flex align-items-start">
                  <div style="width: 30px;">
                    <font-awesome-icon :icon="['fas', 'map-marker-alt']" size='lg' />
                  </div>
                  <div class="flex-fill d-flex flex-column pl-2">
                    <b>地址</b>
                    <span>{{showPointData.address}}</span>
                  </div>
                </div>
              </b-list-group-item>

              <b-collapse class="border-0" v-model="showPointData.more">
                <b-list-group-item class="border-top-0 border-left-0 border-right-0">
                  <div class="d-flex align-items-start">
                    <div style="width: 30px;">
                      <font-awesome-icon :icon="['fas', 'globe-asia']" size='lg' />
                    </div>
                    <div class="flex-fill d-flex flex-column pl-2" style="max-width: 100%">
                      <b>官網</b>
                      <div class="w-100">
                        <a :href="showPointData.websiteUrl" target="_blank"
                          v-if="showPointData.websiteUrl"
                          class="flex-fill pms-content-1">{{showPointData.websiteUrl}}</a>

                        <span class="flex-fill pms-content-1" v-if="!showPointData.websiteUrl">
                          -
                        </span>
                      </div>
                    </div>
                  </div>
                </b-list-group-item>

                <b-list-group-item class="border-left-0 border-right-0">
                  <div class="d-flex align-items-start">
                    <div style="width: 30px;">
                      <font-awesome-icon :icon="['fas', 'phone']" size='lg' />
                    </div>
                    <div class="flex-fill d-flex flex-column pl-2">
                      <b>電話</b>
                      <span>{{showPointData.phone ? showPointData.phone : '-'}}</span>
                    </div>
                  </div>
                </b-list-group-item>

                <b-list-group-item class="border-left-0 border-right-0">
                  <div class="d-flex align-items-start">
                    <div style="width: 30px;">
                      <font-awesome-icon :icon="['fas', 'clock']" size='lg' />
                    </div>
                    <div class="flex-fill d-flex flex-column pl-2">
                      <b>營業時間</b>
                      <div v-html="showPointData.openTime ? showPointData.openTime : '-'"></div>
                    </div>
                  </div>
                </b-list-group-item>
              </b-collapse>

              <div class="d-flex align-items-center justify-content-center py-2"
                @click="showPointData.more = !showPointData.more">
                <font-awesome-icon :icon="['fas', 'ellipsis-h']" size='lg' />
              </div>

            </b-list-group>

            <div class="w-100" v-if="showPointEdit">
              <div class="d-flex align-content-center justify-content-end px-4 py-2">
                <button class="btn btn-sm text-secondary px-2" @click="openGoogleIframe = true">
                  <font-awesome-icon :icon="['fas', 'map-marked-alt']" size='lg' />
                </button>

                <button class="btn btn-sm text-secondary px-2" @click="openImage = true">
                  <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" size='lg' />
                </button>
              </div>

              <b-list-group class="text-secondary border-top" flush>
                <b-list-group-item>
                  <div class="d-flex align-items-start">
                    <div style="width: 30px;">
                      <font-awesome-icon :icon="['fas', 'signature']" size='lg' />
                    </div>
                    <div class="flex-fill d-flex flex-column pl-2">
                      <b>景點名稱</b>
                      <b-form-input v-model="showPointEditData.name"></b-form-input>
                    </div>
                  </div>
                </b-list-group-item>

                <b-list-group-item>
                  <div class="d-flex align-items-start">
                    <div style="width: 30px;">
                      <font-awesome-icon :icon="['fas', 'pencil-alt']" size='lg' />
                    </div>
                    <div class="flex-fill d-flex flex-column pl-2">
                      <b>介紹</b>
                      <b-form-textarea rows="3" max-rows="3"
                        v-model="showPointEditData.descriptionDetail">
                      </b-form-textarea>
                    </div>
                  </div>
                </b-list-group-item>

                <b-list-group-item>
                  <div class="d-flex align-items-start">
                    <div style="width: 30px;">
                      <font-awesome-icon :icon="['fas', 'map-marker-alt']" size='lg' />
                    </div>
                    <div class="flex-fill d-flex flex-column pl-2">
                      <b>地址</b>
                      <b-form-input v-model="showPointEditData.address"></b-form-input>
                    </div>
                  </div>
                </b-list-group-item>

                <b-list-group-item>
                  <div class="d-flex align-items-start">
                    <div style="width: 30px;">
                      <font-awesome-icon :icon="['fas', 'globe-asia']" size='lg' />
                    </div>
                    <div class="flex-fill d-flex flex-column pl-2">
                      <b>官網</b>
                      <b-form-input v-model="showPointEditData.websiteUrl"></b-form-input>
                    </div>
                  </div>
                </b-list-group-item>

                <b-list-group-item>
                  <div class="d-flex align-items-start">
                    <div style="width: 30px;">
                      <font-awesome-icon :icon="['fas', 'phone']" size='lg' />
                    </div>
                    <div class="flex-fill d-flex flex-column pl-2">
                      <b>電話</b>
                      <b-form-input v-model="showPointEditData.phone"></b-form-input>
                    </div>
                  </div>
                </b-list-group-item>

                <b-list-group-item>
                  <div class="d-flex align-items-start">
                    <div style="width: 30px;">
                      <font-awesome-icon :icon="['fas', 'clock']" size='lg' />
                    </div>
                    <div class="flex-fill d-flex flex-column pl-2">
                      <b>營業時間</b>
                      <b-form-textarea rows="3" max-rows="3" v-model="showPointEditData.openTime">
                      </b-form-textarea>
                    </div>
                  </div>
                </b-list-group-item>

                <div class="d-flex align-items-center justify-content-around py-2">
                  <button class="btn btn-sm btn-outline-success"
                    @click="saveShowPoint()">儲存</button>
                  <button class="btn btn-sm btn-outline-danger"
                    @click="cancelShowPoint()">取消</button>
                </div>
              </b-list-group>
            </div>

          </b-card>
          <!-- {{showPointData}} -->
        </div>
      </div>
    </div>

    <div class="flex-shrink-0 w-100 bg-white pms-schedule-listbox overflow-hidden shadow my-0"
      style="max-width:26rem" :class='{"active": smopen}'>
      <!-- <div class="d-flex h-100"> -->
      <div class="flex-fill h-100 card shadow rounded position-relative">
        <div
          class="w-100 h-100 position-absolute bga-6 d-flex align-items-center justify-content-center"
          style="z-index: 2" v-if="changeLoading">
          <span class="text-white font-up-10">Loading...</span>
        </div>
        <!-- title -->
        <div class="card-header bg-dagee-blue p-1">
          <div class="d-flex align-items-center justify-content-between">
            <router-link :to="{name:'schedule'}" class="btn btn-sm px-2 text-dark">
              <font-awesome-icon :icon="['fas', 'chevron-left']" size='lg' />
            </router-link>

            <div class="w-100">
              <div class="d-flex flex-fill align-items-center" v-if="tagSelect == 'schedule'">
                <div class="px-2 text-dark">
                  <b>修改 : {{changeTime}}</b>
                </div>

                <div class="d-flex align-items-center justify-content-center">
                  <button class="btn btn-sm" :class="modifySchedule ? 'text-success':'text-danger'"
                    @click="modifySchedule=!modifySchedule">
                    <font-awesome-icon :icon="['fas', modifySchedule ? 'lock-open' : 'lock']"
                      size="lg" />
                  </button>
                </div>

                <div class="flex-fill d-flex align-items-center justify-content-end">
                  <button class="btn btn-sm text-secondary" @click="resetDayList()"
                    :disabled="!(changeDayListTemp.before.length > 0)">
                    <font-awesome-icon :icon="['fas', 'undo-alt']" size='lg' />
                  </button>
                  <button class="btn btn-sm text-secondary" @click="cancelResetDayList()"
                    :disabled="!(changeDayListTemp.after.length > 0)">
                    <font-awesome-icon :icon="['fas', 'redo-alt']" size='lg' />
                  </button>

                  <button class="ml-2 btn btn-sm btn-outline-success"
                    @click="saveSchedule()">儲存</button>
                </div>
              </div>

              <div class="d-flex flex-fill align-items-center text-dark"
                v-if="tagSelect == 'searchBox'">
                <b>總數 : <strong>{{SearchList.count}}</strong> 筆</b>
              </div>
            </div>

            <!-- <router-link class="text-white py-0 px-2" :to='{name:"index"}' tab="a">
              <font-awesome-icon :icon="['fas', 'angle-left']" size='lg' />
              {{lang.backpage}}
            </router-link> -->

            <!-- <div class="text-center">{{lang.title}}</div> -->

            <!-- <div class="d-none d-md-block cur-print" @click='openSearchBox()'>
                {{lang.searchlist}}
                <font-awesome-icon :icon="['fas', 'caret-right']" />
              </div> -->

            <!-- <div class="text-center" @click="smopen=!smopen">
              <button class="btn btn-sm py-0 px-2">
                <font-awesome-icon :icon="['fas', 'times']" size="lg" />
              </button>
            </div> -->
          </div>
        </div>
        <div class="d-flex test-height">
          <div class="flex-fill d-flex flex-column shadow-sm position-relative"
            v-if="tagSelect == 'schedule'">
            <div class="w-100">
              <div class="w-100 bg-white d-flex align-items-center justify-content-center p-2">
                <div class="flex-fill d-flex align-items-center justify-content-start ">
                  <font-awesome-icon :icon="['fas', 'edit']" size='lg' v-if='data.Edit'
                    class='cur-print text-danger mr-3' @click='EditScheduleInfo()' />
                  <font-awesome-icon :icon="['fas', 'copy']" size='lg'
                    class='cur-print text-danger mr-3' @click='CopySchedule(data.uid)' />
                  <font-awesome-icon :icon="['far', 'file-word']" size='lg'
                    class='cur-print text-danger' @click='tackQuote(data.uid)' />
                </div>

                <div class="d-flex align-items-center justify-content-center cur-print"
                  @click="autoset(data.uid)">
                  <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"
                    :class='{"text-muted":!autoSetLocation, "text-danger":autoSetLocation}' />
                  <span class="px-1"
                    :class='{"text-muted":!autoSetLocation, "text-danger":autoSetLocation}'>記錄</span>
                  <small class="text-warning">{{tmpdate}}</small>
                </div>
              </div>
              <div class="w-100 d-flex flex-wrap b-back-opacity p-2 text-white">

                <div class="w-100 d-flex align-items-start justify-content-between">
                  <b class="font-up-2 flex-fill">{{data.Title ? data.Title : "尚未輸入"}}</b>
                  <button class="btn btn-sm" @click="AddDay(1)" v-if="data.Edit">
                    <font-awesome-icon :icon="['fas', 'plus']" size="lg"
                      class="cur-print text-white" />
                  </button>
                </div>

                <div class="w-100 d-flex align-items-center justify-content-between">
                  <div class="flex-fill" style="font-size: 16px">
                    <span>{{tranDate(data.StartDate,"YYYY/MM/DD")}}
                      ({{tranDate(data.StartDate,"dd")}})
                    </span>
                    ~
                    <span>{{tranDate(data.EndDate,"YYYY/MM/DD")}}
                      ({{tranDate(data.EndDate ,"dd")}})
                    </span>
                  </div>
                  <span>共
                    {{data.EndDate | moment("diff",data.StartDate,"days") + 1}}
                    {{lang.daytext}}
                  </span>
                </div>

              </div>
            </div>

            <div class="flex-fill d-flex flex-column p-2 overflow-auto can-not-select bg-light">

              <!-- <draggable :list="ScheduleList" class="d-flex flex-column cur-print"
                @end="ChangeDayAll()" :animation="draggableoption.animation"
                :delay='draggableoption.delay' ghost-class="pms-ghost" chosen-class="pms-chosen"> -->
              <draggable :move="CheckMove" :list="ScheduleList" class="d-flex flex-column cur-print"
                @end="changeDayList()" :animation="draggableoption.animation"
                :delay='draggableoption.delay' ghost-class="pms-ghost" chosen-class="pms-chosen">

                <div class="b-day-box mb-2" v-for='(i, keys) in ScheduleList'
                  :key="'day__list__' + keys">
                  <div class="w-100">
                    <div class="w-100 d-flex align-items-center justify-content-between mb-2">
                      <div class="flex-fill d-flex align-items-end">
                        <b class="b-day-index font-up-0 letter-s-1" @click='dayshow(i)' :style="{
                          'color': i.subshow ? dayColor[parseInt(keys % 7)] : 'white',
                          'background': i.subshow ? 'white' : dayColor[parseInt(keys % 7)],
                          'box-shadow': 'inset 0px 0px 2px 1px' + dayColor[parseInt(keys % 7)]}">
                          第{{keys+1}}天
                        </b>

                        <b class="b-day-date px-2">
                          {{tranDate(i.start,"MM/DD")}}
                          ({{tranDate(i.start,"dd")}})
                        </b>

                        <span>共 {{i.sub.length}} 個</span>
                      </div>

                      <div class="d-flex align-items-center justify-content-between"
                        @click='StartTimeEdit(i)'>
                        <b class="btn-link">{{i.starttime}} 出發</b>
                      </div>

                    </div>


                  </div>

                  <b-collapse v-model="i.subshow">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="flex-fill">
                        <font-awesome-icon :icon="['fas', 'lock']" />
                        <!-- <font-awesome-icon :icon="['fas', 'lock-open']" /> -->
                      </div>
                      <div>
                        <span>總距離約 {{totalDistance(i.sub)}} 公里</span>
                      </div>

                      <button class="btn btn-sm" @click="distanceCount(i)" v-if="getLevel"
                        :class="i.googleDistance ? 'text-success' : 'text-secondary'">
                        <font-awesome-icon :icon="['fab', 'google']" size="lg" />
                      </button>
                    </div>
                    <draggable :list="i.sub" tag='ul' group="schedule" @end="changeDayList()"
                      :move="CheckMove" class='w-100 m-0' :animation="draggableoption.animation"
                      :delay='draggableoption.delay' ghost-class='pms-ghost'
                      chosen-class='pms-chosen'>
                      <!-- <draggable :list="i.sub" tag='ul' group="schedule" @end="ChangeDayAll()"
                      :move="CheckMove" class='w-100 shadow-sm px-2 m-0'
                      :animation="draggableoption.animation" :delay='draggableoption.delay'
                      ghost-class='pms-ghost' chosen-class='pms-chosen'> -->

                      <div class="w-100 rounded mb-1 pt-1 pr-1" v-for="(j, index) in subShow(i)"
                        :key="'day__list__sub__' + keys + '-' + index">
                        <div class="w-100 d-flex align-items-center justify-content-between">
                          <div class="d-flex flex-wrap align-items-center justify-content-center"
                            style="min-width: 60px; max-width: 60px">
                            <div class="w-100 text-center">{{j.starttime}}</div>

                            <div class="w-100 d-flex justify-content-center position-relative"
                              :style="{color: dayColor[parseInt(keys % 7)]}"
                              @click="showPointInfor(j, 'schedule')">
                              <b-icon class="fa-2x cur-print" icon="geo-alt"></b-icon>
                              <b class="position-absolute text-center rounded-circle d-flex align-items-center justify-content-center bg-white"
                                style="top: 1px; width:20px; max-width:20px; height:20px; max-height:20px;">
                                {{index+1}}
                              </b>
                            </div>

                            <div class="w-100 text-center">{{j.endtime}}</div>
                          </div>
                          <div class="flex-fill rounded p-2 shadow-sm bg-white"
                            :class="{'psm-focus' : ClickMyPoint==(j.place_id+keys+index)}">
                            <div class="d-flex align-items-center justify-content-center pb-1">
                              <span class="flex-fill text-danger">{{formatTime(j.stoptime)}}</span>
                              <div>
                                <font-awesome-icon :icon="['fas', 'check-double']" size="lg"
                                  v-if="j.parallel.length > 1" />
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" size="lg"
                                  @click="EditPoint(keys,j,index)" />
                              </div>
                            </div>
                            <div class="w-100" @click="Markerclick(j.place_id, keys,index,j)">
                              <div class="pms-content-1">
                                <b>{{j.title}}</b>
                              </div>
                              <div class="pms-content-1 text-secondary">{{j.address}}</div>
                            </div>
                          </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex justify-content-center align-self-stretch"
                            style="min-width: 60px; max-width: 60px">
                          </div>
                          <div
                            class="flex-fill d-flex align-items-center justify-content-between pt-3 pb-2 px-2"
                            v-if="index < i.sub.length - 1">
                            <div class="flex-fill">
                              <font-awesome-icon :icon="['fas', 'car']" size="lg" />
                              <b class="letter-s-0" style="font-size: 13px">
                                約{{j.movetime}}分，
                                距離{{j.distance}}公里
                              </b>
                              <!-- <b v-if="j.movemethod=='setting'">({{lang.text.setting}})</b> -->
                            </div>
                            <div>
                              <font-awesome-icon class="text-secondary"
                                :icon="['fas', 'ellipsis-h']" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </draggable>
                  </b-collapse>
                </div>
              </draggable>
            </div>

            <!-- <b-nav class="d-flex align-items-center justify-content-center">
              <b-nav-item class="px-2">交通</b-nav-item>
              <b-nav-item class="px-2">特色</b-nav-item>
              <b-nav-item class="px-2">提醒</b-nav-item>
              <b-nav-item class="px-2">自費</b-nav-item>
              <b-nav-item class="px-2">行程</b-nav-item>
              <b-nav-item class="px-2">刪除</b-nav-item>
            </b-nav> -->

            <div class="w-100 d-flex align-items-stretch justify-content-center"
              style="min-height: 65px">
              <div class="flex-fill d-flex align-items-center justify-content-center p-2 border">
                交通
              </div>
              <div class="flex-fill d-flex align-items-center justify-content-center p-2 border">
                特色
              </div>
              <div class="flex-fill d-flex align-items-center justify-content-center p-2 border">
                提醒
              </div>
              <div class="flex-fill d-flex align-items-center justify-content-center p-2 border">
                自費
              </div>
              <div class="flex-fill d-flex align-items-center justify-content-center p-2 border">
                <span>行程</span>
              </div>
              <div
                class="flex-fill d-flex flex-column align-items-center justify-content-center p-2 border">
                <div>景點</div>
                <div>刪除</div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-fill shadow-sm" v-if="tagSelect == 'searchBox'"
            style="max-width: calc(100% - 2.5rem) !important;">
            <new-search-box :SearchData="SearchList" :AddSchedule="openAddBox"
              :SearchMarkClick="SearchMarkClick" :showPointInfor="showPointInfor"
              @moreSearchData="moreSearchData"></new-search-box>

            <!-- <SearchdataBox :IsOpen.sync="IsSearchBox" :IsSearchHide.sync='IsSearchHide'
            :SearchData='SearchList' :SearchMarkClick="SearchMarkClick"
            :SearchSelected='SearchPlace' :AddSchedule="openAddBox" /> -->
          </div>

          <div class="d-flex flex-fill shadow-sm" v-if="tagSelect == 'ownPoint'"
            style="max-width: calc(100% - 2.5rem) !important;">
            <Add-point></Add-point>
          </div>

          <div class="d-flex flex-fill shadow-sm" v-if="tagSelect == 'google'"
            style="max-width: calc(100% - 2.5rem) !important;">
            <!-- <iframe src="https://yahoo.com.tw"></iframe> -->
          </div>

          <div class="pms-schedule-ctrlbar">
            <!-- <div class="pms-close text-center" @click="smopen=!smopen">
              <button class="btn btn-sm">
                <font-awesome-icon :icon="['fas', 'times']" size="lg" />
              </button>
            </div> -->
            <div class="h-100 pms-bookmark position-relative">
              <div class="w-100 position-absolute pms-bookmark to-map">
                <ul>
                  <li class='my-map' @click="tagChange('smopen')">
                    <a>地圖</a>
                  </li>

                  <li :class="{'active': tagSelect=='schedule'}" @click="tagChange('schedule')">
                    <a>行程</a>
                  </li>

                  <li :class="{'active': tagSelect=='searchBox'}" @click="tagChange('searchBox')">
                    <a>搜尋</a>
                  </li>

                  <li :class="{'active': tagSelect=='favorite'}" @click="tagChange('favorite')">
                    <a class="d-flex align-items-center justify-content-center">
                      <font-awesome-icon class="text-danger" :icon="['fas', 'heart']" size="lg" />
                    </a>
                  </li>

                  <li :class="{'active': tagSelect=='ownPoint'}" @click="tagChange('ownPoint')">
                      <a>自建景點</a>
                  </li>

                  <li :class="{'active': tagSelect=='google'}" @click="tagChange('google')">
                    <a>
                      搜尋介紹
                    </a>
                  </li>
                </ul>

                <!-- <ul>
                  <li :class="{'active': tagSelect=='schedule'}" @click="tagChange('schedule')">
                    <a>行程</a>
                  </li>
                  <li :class="{'active': tagSelect=='searchBox'}" @click="tagChange('searchBox')">
                    <a>搜尋</a>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="pms-schedule-ctrlbar">
        <div class="pms-close text-center" @click="smopen=!smopen">
          <button class="btn btn-sm">
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </button>
        </div>
        <div class="pms-bookmark">
          <ul class="w-100">
            <li :class="{'active': tagSelect=='schedule'}" @click="tagSelect = 'schedule'">
              <a>行程內容</a>
            </li>
            <li :class="{'active': tagSelect=='searchBox'}" @click="tagSelect = 'searchBox'">
              <a>搜尋列表</a>
            </li>
          </ul>
        </div>
      </div> -->
      <!-- </div> -->
    </div>
    <!-- 20200413 -->
    <div class="flex-fill d-flex flex-column position-relative overflow-auto" style="">
      <!-- 控制 bar -->
      <!-- <div class="w-100 position-absolute" style="z-index:1001">
        <div class="w-100 pt-2 pb-1 bg-white pms-searchBox" :class="{'active': dropdownOpen}">
          <div class="w-100 d-flex justify-content-center pms-typesearch-box">
            <div class="pms-typesearch-item" v-for='(item, index) in quittype' :key="index"
              :class='{"active": item.name==quitdata.method}' @click='selectedquittype(item.name)'>
              <a href="#point" class="font-up-0" @click.prevent>
                <font-awesome-icon class="mr-1 mr-md-2" :icon="['fas', item.icon]" size="lg" />
                {{lang.text[item.name]}}
              </a>
            </div>
          </div>

          <div
            class="d-flex align-items-center justify-content-center justify-content-md-start p-0 p-md-1 py-2">
            <Searchbtn v-model="quitdata.searchtext" :search='SearchData'>
              <template #searchbtn='{searchbtn}'>
                <b-button-group size="sm" class='b-button-checked mr-0 '>
                  <b-button class='b-button-item text-nowrap'
                    :class="{'active':quitdata.searchtype=='Preferences'}"
                    @click='searchbtn("Preferences")'>
                    {{lang.text.Preferences}}</b-button>
                  <b-button class='b-button-item' :class="{'active':quitdata.searchtype=='google'}"
                    @click='searchbtn("google")'>{{lang.text.GoogleSearch}}</b-button>
                </b-button-group>
              </template>
            </Searchbtn>



            <div class='align-items-end col'>
            <input type="checkbox" id='autoreportGPS' v-icheck-style="autoreportGPS.isCheck"
            :value="true" :checked='autoreportGPS.isCheck.indexOf(true)>-1'>
            <label for="autoreportGPS" >{{lang.text.autoreportGPS}}</label>
          </div>
          </div>
        </div>

        <div class="w-100 mt-1 d-flex justify-content-center pms-dropdown-search">
          <font-awesome-icon :icon="['fas', 'chevron-circle-down']" size="2x"
            @click="dropdownOpen=!dropdownOpen" v-if="!dropdownOpen" />
          <font-awesome-icon :icon="['fas', 'chevron-circle-up']" size="2x"
            @click="dropdownOpen=!dropdownOpen" v-if="dropdownOpen" />
        </div>
      </div> -->

      <div class="w-100 h-100">
        <!-- <div class="pms-research-box" v-if='IsSearchBox'>
          <button class='pms-btn' @click='SearchData()'>{{lang.researcharea}}</button>
        </div> -->
        <div class='w-100 pms-map-box position-relative'>
          <!-- {{mapdata}} -->
          <MapView :zoom.sync='mapdata.zoom' :mapcenter.sync='mapdata.mapcenter'
            :SearchList="SearchListComputed" :SearchMarkClick="SearchMarkClick"
            :SearchSelected='SearchPlace' :AddSchedule="openAddBox" :Markerclick='Markerclick'
            :ClickMyPoint='ClickMyPoint' id='osmmap' :PointMethod="{EditPoint:EditPoint}"
            @openSearchBox="openSearchBox" :showPointInfor="showPointInfor" :ref='"IMap"' />

          <div
            class="w-100 d-flex align-items-center pms-map-list-schedule can-not-select position-absolute overflow-hidden">
            <div
              class="w-100 h-100 position-absolute bga-6 d-flex align-items-center justify-content-center"
              style="z-index:2" v-if="changeLoading">
              <span class="text-white font-up-10">Loading...</span>
            </div>
            <div style="overflow-x: auto; overflow-y: hidden">

              <draggable :list="ScheduleList" v-if='ScheduleList.length>0'
                class='d-flex align-items-center' style="height: 4rem" @end="ChangeDayAll(false)"
                :move="CheckMove" :animation="draggableoption.animation"
                :delay='draggableoption.delay' :touch-start-threshold='4' ghost-class='pms-ghost'
                chosen-class='pms-chosen-xx'>
                <div class="h-100 px-1 d-flex align-items-center cur-print"
                  v-for="(i, keys) in ScheduleList" :key="keys">

                  <!-- <div class="pms-map-item-day d-flex align-items-center px-3 rounded-pill"
                :style="{background:dayColor[parseInt(keys % 7)]}" @click='i.subshow=!i.subshow'>
                <b class="text-nowrap text-white">第 {{keys + 1}} 天</b>
              </div> -->
                  <b class="text-center font-up-0 letter-s-1" @click='i.subshow=!i.subshow' :style="{
                          'color': i.subshow ? dayColor[parseInt(keys % 7)] : 'white',
                          'background': i.subshow ? 'white' : dayColor[parseInt(keys % 7)],
                          'box-shadow': 'inset 0px 0px 2px 1px' + dayColor[parseInt(keys % 7)], 
                          'min-width': '70px',
                          'border-radius': '2rem'}">
                    第{{keys+1}}天
                  </b>

                  <draggable class='pms-map-item-point d-flex' :list="i.sub" group="schedule"
                    @end="ChangeDayAll(false)" :move="CheckMove"
                    :animation="draggableoption.animation" :delay='draggableoption.delay'
                    :touch-start-threshold='4' ghost-class='pms-ghost' chosen-class='pms-chosen-xx'
                    :style="{color:dayColor[parseInt(keys%7)]}">
                    <div class="d-flex align-items-center px-2 " v-for="(j, index) in i.sub"
                      :key="index" @click="Markerclick(j.place_id,keys,index,j)" v-if="i.subshow">
                      <div class="flex-fill d-flex flex-wrap align-items-center cur-print">
                        <div class="w-100 d-flex justify-content-center align-items-center">
                          <div class="position-relative">
                            <b-icon class="fa-2x cur-print" icon="geo-alt"></b-icon>
                            <b class="w-100 position-absolute text-center rounded-circle bg-white d-flex align-items-center justify-content-center"
                              style="left: 5px; top:1px; height:20px; width:20px;max-height:20px; max-width:20px">
                              <span>{{index+1}}</span>
                            </b>
                          </div>
                        </div>
                        <div class="w-100 pms-map-item-point-text pms-text-nowrap "
                          style="font-size: 16px">{{j.title}}</div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </draggable>
            </div>
          </div>
        </div>
        <!--  -->
        <!-- here -->
        <!-- <div class="w-100" style="min-height:1rem; background:rgba(161, 217, 239, 0.3)"></div> -->
        <!-- :class='["pms-day-color-"+(parseInt(keys%7)+1)]' -->

        <!-- <div class="w-100 text-right fixed-bottom" style="bottom: 16rem; z-index: 1000" v-if="!SearchList.lenght > 0">
          <button class=" btn btn-sm" @click="openSearchBox()" >
            <font-awesome-icon :icon="['fas', 'search-plus']" size="2x" v-if="!IsSearchBox" />
            <font-awesome-icon :icon="['fas', 'search-minus']" size="2x" v-if="IsSearchBox" />
          </button>
        </div> -->

        <div class="b-searchdata-box fixed-bottom" style="bottom: 0"
          :style="{'min-height': (IsSearchHide) ? '14rem':'0rem'}" v-show='IsSearchBox'>
          <LoadingBlock style="z-index:10000" :showLoading="loading"></LoadingBlock>

          <SearchdataBox style="z-index: 1040" :IsOpen.sync="IsSearchBox"
            :IsSearchHide.sync='IsSearchHide' :SearchData='SearchList'
            :SearchMarkClick="SearchMarkClick" :SearchSelected='SearchPlace'
            :AddSchedule="openAddBox" @moreSearchData="moreSearchData" />
        </div>

      </div>
    </div>

    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <b-modal v-model="DayStartTime.show" centered size="sm" @ok="EditStartTimeOk()"
      @hide="hideStartTime()" :header-class="['py-2', 'bg-dagee']"
      :footer-class="['p-0', 'bg-white']">

      <template v-slot:modal-header="{ close }">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="flex-fill">
            <b>編輯時間</b>
          </div>
          <div @click="close()">
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </div>
        </div>
      </template>

      <template v-slot:default>
        <div class="d-flex align-items-center">
          <b-input-group size="sm" :append="lang.text.hour">
            <b-form-select v-model='DayStartTime.Startime.hour'>
              <option :value="number - 1" v-for="number in 24"> {{number - 1}} </option>
            </b-form-select>
          </b-input-group>

          <b-input-group size="sm" :append="lang.text.min">
            <b-form-select v-model='DayStartTime.Startime.min'>
              <option :value="number - 1" v-for='number in 60'>{{number - 1}}</option>
            </b-form-select>
          </b-input-group>
        </div>
      </template>

      <template v-slot:modal-footer="{ ok, hide, cancel }">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="flex-fill d-flex justify-content-center">
            <button class="btn btn-md text-success" @click="ok()">
              <font-awesome-icon :icon="['fas', 'check']" />
            </button>
          </div>

          <button class="btn btn-md text-danger" @click="DeleteDay(DayStartTime.uid, cancel)">
            <font-awesome-icon :icon="['fas', 'trash-alt']" />
          </button>
        </div>
      </template>
    </b-modal>

    <!-- <b-modal v-model="DayStartTime.show" :centered='true' @ok='EditStartTimeOk()'
      @hide='hideStartTime()'>

      <template slot:modal-header="{ close }">
        <div class="bg-info d-flex align-items-center justify-content-between">
          <div>修改天數</div>
          <div>
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
        </div>
      </template>

      <template slot="default">

        <div class="row">
          <div class="col">
            <b-input-group size="sm" :append="lang.text.hour">
              <b-form-select v-model='DayStartTime.Startime.hour'>
                <option :value="number-1" v-for='number in 24'>{{number-1}}</option>
              </b-form-select>
            </b-input-group>
          </div>
          <div class="col">

            <b-input-group size="sm" :append="lang.text.min">
              <b-form-select v-model='DayStartTime.Startime.min'>
                <option :value="number-1" v-for='number in 60'>{{number-1}}</option>
              </b-form-select>
            </b-input-group>
          </div>
        </div>
      </template>
      <template tag='div' slot="modal-footer" slot-scope="{ ok, cancel, hide }">
        <b-button size="md" variant="success" @click="ok()">
          <font-awesome-icon :icon="['fas', 'check']" />
        </b-button>
      </template>
    </b-modal> -->

    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- ========================================================================= -->
    <!-- :Secheduleinfo="Secheduleinfo" -->
    <EditPoint :pointdata="pointInfo" :show.sync='pointInfoShow'></EditPoint>

    <ComfirmBox :show.sync='Confirm.show' :context='Confirm.context' :title='Confirm.name'
      :okfunc='Confirm.okfunc'></ComfirmBox>

    <EditSchedulePage :Secheduleuid='Secheduleinfo.uid' :reback="EditReback"
      :show.sync="EditScheduleInfoShow.show"></EditSchedulePage>

    <PayList :show.sync='PayListbox.show' :Secheduleuid='Secheduleinfo.uid' :reback="PayListReback">
    </PayList>

    <UpdateImage :pointData="showPointData" :open.sync="openImage" v-if="openImage"></UpdateImage>

    <UpdateGoogleIframe :pointData="showPointData" :open.sync="openGoogleIframe"
      v-if="openGoogleIframe"></UpdateGoogleIframe>
    <!-- <EditElement :pointElement={}></EditElement> -->
  </div>
</template>

<script>
  // @ is an alias to /src
  import controler from "./EditSchedule";
  import "@/style/Edit.scss";
  export default controler

</script>


<style lang="scss">
  .VueCarousel-dot-container {
    margin: 0 !important;

    button {
      margin: 0 !important;
    }
  }

</style>
