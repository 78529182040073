import store from "@/store";
import LoadingBlock from "@/components/Loading/LoadingBlock";
export default {
  name: 'SearchdataBox',
  data() {
    return {
      GoogleAPI: process.env.VUE_APP_GOOGLESEARCHKEY,
      sortBy: [{
        name: "距離",
        values: "distance"
      }, {
        name: "地址",
        values: "address"
      }, {
        name: "價錢",
        values: "price"
      }],

      sortByType: "distance",

      sortMethod: [{
          name: "大→小",
          values: "desc",
        },
        {
          name: "小→大",
          values: "asc",
        }
      ],

      sortType: "desc"
    };
  },
  computed: {
    showLoading: {
      get() {
        return this.$props.loading
      }
    },
    lang: {
      get() {
        return store.state.Public.lang.schedule
      }
    },
    GetSchedule: {
      get() {
        return store.getters.schedule.GetSchedule;
      }
    },
    SearchBoxListStyle() {
      return {
        width: (16.1 * this.SearchData.data.length) + "rem"
      };
    },
    sortMethodName() {
      return () => {
        return _.head(_.filter(this.sortMethod, (item, key) => {
          return item.values == this.sortType;
        })).name;
      }
    },

    sortByMethodName() {
      return () => {
        return _.head(_.filter(this.sortBy, (item, key) => {
          return item.values == this.sortByType;
        })).name;
      }
    },

    orderByPoint() {
      return _.orderBy(this.SearchData.data, [this.sortByType], [this.sortType]);
    }

  },

  methods: {
    showSearchBox() {
      this.$store.commit("schedule/setHideSearchBox", !this.IsSearchHide);
    },

    hideSearchBox() {
      this.$store.commit("schedule/setShowSearchBox", !this.IsOpen);
    },

    moreClick() {
      const count = this.SearchData.count;
      const page = this.SearchData.nextpage_token;
      this.$emit("moreSearchData", page);
    },

    sortChange() {
      this.sortType = (this.sortType == "desc") ? "asc" : "desc";
    },

    sortByChange() {
      this.sortByType = (this.sortByType == "distance") ? 'address' : ((this.sortByType ==
        "address") ? "price" : "distance");
    },

    goFirst() {
      document.querySelector(".list-box").scrollLeft = 0;
    }

  },

  watch: {
    SearchSelected(data) {
      if (data) {
        const listbox = document.querySelector(".list-box");
        document.querySelector(".list-box").scrollLeft = document.querySelector(
          ".box" + data).offsetLeft - 10;
      }
    }
  },
  props: {
    IsOpen: Boolean,
    SearchData: Object,
    SearchMarkClick: Function,
    SearchSelected: String,
    IsSearchHide: Boolean,
    AddSchedule: Function,
    loading: Boolean,
  },
  components: {
    LoadingBlock,
  }
};
