<template>
  <div>
    <!-- 未完成 -->
    <b-modal v-model="boxshow" :footer-class='["bg-white"]' @hide='hidebox()'>
      <template slot="modal-header" slot-scope="{ close }">
        <h5> {{lang.Paylist.Title}}</h5>
      </template>
      <template slot="default" slot-scope="{ hide }">
        <b-tabs fill>
          <b-tab :title="lang.Paylist.marksList" active>
            <div class="w-100 mt-1 cur-print" v-for="(item,index) in ScheduleList"
              @click='showitem.indexOf(index)>-1?showitem.splice(showitem.indexOf(index),1):showitem.push(index)'>
              <div class="d-flex justify-content-between">
                <div class="text-white" style='width:40px;'>
                  <span class='rounded-pill pl-2 pt-1 pr-2 pb-1 pms-bg-dagee'>
                    D{{index+1}}</span>
                </div>
                <div>
                  Date:{{item.start | moment("YY/MM/DD")}}-{{item.start | moment("dd")}}
                </div>
                <div>
                  <small>
                    {{lang.text.nowprice}}{{item.nowprice | currency}}
                  </small>
                </div>
                <div>
                  <small>
                    {{lang.text.maxprice}}{{item.maxprice | currency}}
                  </small>
                </div>
              </div>
              <div class="clearfix pl-3 pt-2" v-if="showitem.indexOf(index)>-1">
                <div class="row" v-for='i in timetype'>
                  <div class="col-1">{{lang.Paylist[i.name]}}</div>
                  <div class="col">
                    <div class="clearfix mt-1" v-for=' (si,keys) in  SelectMethod(item,i)'>
                      <div class="d-flex">
                        <div class="mr-2">{{lang.Paylist[keys]}}</div>
                        <div class="d-flex flex-wrap">
                          <div class="pms-Method-item mt-1 ml-1" v-for='sui in si'>
                            {{sui.title}}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="lang.Paylist.paylist">

          </b-tab>
        </b-tabs>
      </template>

      <div slot="modal-footer" slot-scope="{ ok, cancel, hide }" class="w-100">
      </div>
    </b-modal>
  </div>
</template>

<script>
  import ctrl from "./js/PayList";
  export default ctrl;

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../assets/scss/variables';

  .pms-group-item-hover {
    &:hover {
      background: $dagee-color;
    }
  }

  .pms-searchAjax-list {
    position: absolute;
    width: 100%;
    margin-top: 2rem;
    background: #fff;
    z-index: 100;
  }

</style>
